<template>
  <Toast />
  <Dialog v-model:visible="activarModal" :style="{ width: '750px' }" header="REGISTRAR NUEVA ORDEN DE COMPRA"
    :modal="true" @hide="closeModal" class="p-fluid">
    <div class="col-12 md:col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="proveedor_id"><strong>Seleccione Proveedor: <span class="p-invalid">*</span></strong></label>
          <Dropdown v-model="proveedorSelect" :options="proveedores" autofocus optionLabel="nombre_completo"
            :filter="true" placeholder="Seleccione un Proveedor" @change="buscarLaboratorios">
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <label for="laboratorios"><strong>Laboratorios del Proveedor:</strong></label>
          <MultiSelect v-model="laboratoriosSelect" :options="laboratorios" optionLabel="nombre" display="chip"
            disabled />
        </div>
        <div class="field col-12 md:col-2">
          <label for="laboratorios"><strong>Fecha Compra:</strong> </label>
          <Calendar v-model="fecha_compra" required="true" dateFormat="dd/mm/yy" :showIcon="true" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="productos"><strong>Productos del Proveedor: <span class="p-invalid">*</span></strong>
            {{ cantidadProductosProveedor }}</label>
          <div class="p-inputgroup">
            <Dropdown v-model="productoSeleccionado" :options="grupoProductos" placeholder="Seleccione un producto"
              optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" :filter="true"
              :disabled="!laboratoriosSelect.length">
            </Dropdown>
            <Button icon="pi pi-plus" v-if="'Producto Crear' in auth.user.permissions" class="p-button"
              v-tooltip.top="'Nuevo Producto'" @click="activarProducto" />
          </div>
          <small class="p-invalid text-red-500" v-if="errors.productoSeleccionado">{{ errors.productoSeleccionado[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for=""><strong>Cantidad a Agregar: <span class="p-invalid">*</span></strong></label>
          <InputNumber :inputClass="'text-right'" id="cantidad" v-model="cantidad" mode="decimal" placeholder="1,00"
            class="text-right" showButtons :min="0" :min-fraction-digits="2" locale="de-ED"
            @keyup.enter="agregarProductoDetalle" :loading="enviando" />
          <small class="p-invalid text-red-500" v-if="errors.cantidad">{{
            errors.cantidad[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for=""><strong>Agregar al Detalle: <span class="p-invalid">*</span></strong>
          </label>
          <Button label="Agregar al Detalle" icon="pi pi-shopping-cart" @click="agregarProductoDetalle"
            :loading="enviando" />
        </div>
      </div>
      <div class="field col-12 md:col-12">
        <label for="detalle"><strong>DETALLE DE COMPRAS:</strong></label>
        <DataTable :value="detalleProductos" responsiveLayout="scroll" editMode="cell"
          @cell-edit-complete="onCellEditComplete" class="p-datatable-sm" responsive="true" :rowhover="true"
          stripedRows showGridlines>
          <Column field="producto.value" header="ID">
            {{ data.producto.value }}
          </Column>
          <Column field="producto.label" header="Descripción">
            {{ data.producto.label }}
          </Column>
          <Column field="cantidad" header="Cantidad" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.cantidad) }}
            </template>
            <template #editor="{ data, field }">
              <InputNumber input-class="text-right" v-model="data[field]" autofocus :minFractionDigits="2"
                locale="de-DE" />
            </template>
          </Column>
          <Column field="precio" header="Precio Unitario" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.precio) }}
            </template>
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" autofocus input-class="text-right" :minFractionDigits="2"
                locale="de-DE" />
            </template>
          </Column>
          <Column header="Subtotal" class="text-right">
            <template #body="slotProps">
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.cantidad * slotProps.data.precio
                )
              }}
            </template>
          </Column>
          <Column field="quantity" header="Quitar">
            <template #body="slotProps">
              <Button @click="quitar(slotProps)" v-tooltip.top="'Quitar Producto'" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text" />
            </template>
          </Column>
          <template #empty>
            <span class="flex align-items-center justify-content-center p-invalid">No existen Productos en el
              Detalle!</span>
          </template>

          <template #footer>
            <div class="text-right">
              <h4>
                TOTAL OC:
                <strong>{{
                  convertirNumeroGermanicFormat(totalCompra)
                }}</strong>
                Bs.
              </h4>
            </div>
          </template>
        </DataTable>
      </div>
      <div class="field col-12 md:col-12">
        <label for="observacion"><strong>Observaciones: </strong> </label>
        <Textarea id="observacion" v-model="observacion" required="true" rows="2" placeholder="Ingrese una observación"
          @keyup.enter="guardarCompra">
                    </Textarea>
      </div>
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-lg" @click="closeModal"
        :disabled="enviando" :loading="enviando" />
      <Button label="Guardar" icon="pi pi-check" class="p-button-primary p-button-lg" v-tooltip.top="'Guardar Compra'"
        @click="guardarCompra" :disabled="enviando" :loading="enviando" />
    </template>
  </Dialog>
  <ProveedorCreate :show="proveedorModal" :proveedor="proveedor" @closeModal="cerrarModalProveedor"
    @actualizarListado="cargarProveedores">
  </ProveedorCreate>
  <ProductoCreate :mostrar="productoModal" :producto="producto" @closeModal="cerrarModalProducto"
    @actualizarListadoProductos="cargarProductos">
  </ProductoCreate>
</template>

<script>
import ProveedorCreate from "@/module/proveedores/ProveedorCreate.vue";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";
import ProveedorService from "@/service/ProveedorService";
import OrdenCompraService from "@/service/OrdenCompraService";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";

export default {
  name: "ComprasCreate",
  emits: ["closeModal", "actualizarListadoOC"],
  components: {
    ProveedorCreate,
    ProductoCreate,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enviando: false,
      fecha_compra: null,
      activarModal: this.mostrar,
      proveedorSelect: {},
      proveedores: [],
      laboratoriosSelect: {},
      laboratorios: [],
      grupoProductos: [],
      productoSeleccionado: {},
      cantidad: 1,
      precio: null,
      observacion: null,
      detalleProductos: [],
      errors: {},
      proveedorModal: false,
      productoModal: false,
      proveedor: { estado: { label: "Activo", value: 1 } },
      producto: { estado: { label: "Activo", value: 1 } },
      totalCompra: 0,
      cantidadProductosProveedor: 0,
      precio_compra_producto: null,
    };
  },
  proveedorService: null,
  ordenCompraService: null,
  productService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.proveedorService = new ProveedorService();
    this.ordenCompraService = new OrdenCompraService();
    this.productService = new ProductService();
  },
  mounted() {
    this.cargarProveedores();
    this.cargarProductos();
  },
  methods: {
    calcularTotal() {
      this.totalCompra = 0;
      this.detalleProductos.forEach((item) => {
        this.totalCompra += item.cantidad * item.precio;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarProductos() {
      this.productService.getAllProductos().then((data) => {
        this.productos = data.productos;
      });
    },
    cargarProveedores() {
      this.proveedorService
        .getProveedores()
        .then((data) => {
          this.proveedores = data.proveedores;
        })
        .catch((error) => console.log(error));
    },
    cerrarModalProveedor() {
      this.proveedorModal = false;
      this.proveedor = {};
      this.cargarProveedores();
    },
    activarProducto() {
      this.productoModal = true;
      this.producto = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProducto() {
      this.productoModal = false;
      this.producto = {};
      this.cargarProveedores();
    },
    validarCantidad(data) {
      if (data.cantidad <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "LA CANTIDAD DEBE SER MAYOR A CERO",
          life: 6000,
        });
        data.cantidad = 1;
      }

      this.productos.forEach((item) => {
        if (item.id == data.producto.value) {
          if (data.cantidad > item.stock_maximo && item.stock_maximo > 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "La cantidad supera el Stock Máximo de Compra" + "Permitido: " + item.stock_maximo,
              life: 6000,
            });
            data.cantidad = 1;
          }
        }
      });
    },
    onCellEditComplete(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      this.validarCantidad(data);
      this.calcularTotal();
    },

    validarOrdenCompra() {
      if (this.proveedorSelect.id == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE UN PROVEEDOR",
          life: 6000,
        });
        return false;
      }

      if (this.fecha_compra == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE LA FECHA DE COMPRA",
          life: 6000,
        });
        return false;
      }

      if (this.detalleProductos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "DEBE SELECCIONAR UN PRODUCTO COMO MINIMO",
          life: 6000,
        });
        return false;
      }

      return true;
    },
    guardarCompra() {
      if (this.enviando) {
        return false;
      }
      if (this.validarOrdenCompra()) {
        this.enviando = true;

        let data = {
          proveedor_id: this.proveedorSelect.id,
          productos: this.detalleProductos,
          observacion: this.observacion,
          fecha_compra: this.fecha_compra,
        };

        this.ordenCompraService
          .guardarOC(data)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Nueva Orden de Compra Solicitada",
              life: 3000,
            });
            this.$emit("actualizarListadoOC");
            this.$emit("closeModal");
            this.closeModal();
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "Error al guardar la Orden de Compra, Verifique su conexión a internet",
              life: 6000,
            });
            this.enviando = false;
          });
      }
    },
    quitar(producto) {
      this.detalleProductos.splice(producto.index, 1);
      this.calcularTotal();
    },
    buscarLaboratorios() {
      if (this.proveedorSelect.id) {
        this.proveedorService
          .obtenerLaboratorios(this.proveedorSelect.id)
          .then((response) => {
            this.grupoProductos = [];
            this.detalleProductos = [];
            this.cantidadProductosProveedor = 0;
            this.laboratorios = this.laboratoriosSelect = response.fabricas;

            this.laboratorios.forEach((laboratorio) => {
              let items = [];

              laboratorio.productos.forEach((producto) => {
                let item = { label: producto.descripcion, value: producto.id };
                items.push(item);
                this.cantidadProductosProveedor++;
              });

              let tmp = {
                label: laboratorio.nombre,
                code: laboratorio.id,
                items: items,
              };
              this.grupoProductos.push(tmp);
            });
          })
          .catch((error) => console.log(error));
      }
    },

    closeModal() {
      this.activarModal = false;
      this.proveedorSelect = {};
      this.laboratoriosSelect = {};
      this.$emit("closeModal");
      this.grupoProductos = [];
      this.detalleProductos = [];
      this.observacion = null;
      this.cantidadProductosProveedor = 0;
    },

    agregarProductoDetalle() {
      this.enviando = true;
      this.errors = {};
      if (!this.productoSeleccionado.label) {
        this.errors.productoSeleccionado = ["Debe seleccionar un producto"];
        this.enviando = false;
        return false;
      }

      //Verificar si el producto ya esta en el detalle
      let existe = this.detalleProductos.find(
        (item) => item.producto.value == this.productoSeleccionado.value
      );

      if (existe) {
        this.errors.productoSeleccionado = [
          "El producto ya esta en el detalle",
        ];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      if (this.cantidad <= 0 || this.cantidad == null) {
        this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La cantidad debe ser mayor a 0",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      // buscon el producto con su stock maximo y verifico si la cantidad es mayor al stock maximo del producto
      let producto = this.productos.find(
        (item) => item.id == this.productoSeleccionado.value
      );

      if (producto.id == this.productoSeleccionado.value) {
        if (
          this.cantidad > producto.stock_maximo &&
          producto.stock_maximo > 0
        ) {
          this.errors.cantidad = [
            "La cantidad no puede ser mayor al stock maximo del producto" +
            " " +
            producto.stock_maximo,
          ];
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "La cantidad no puede ser mayor al stock maximo del producto",
            life: 3000,
          });
          this.enviando = false;
          return false;
        }
      }

      // obtengo el precio anterior del producto seleccionado
      this.ordenCompraService
        .getprecio_compra_producto(this.productoSeleccionado.value)
        .then((response) => {
          let item = {
            producto: this.productoSeleccionado,
            cantidad: this.cantidad,
            precio: parseFloat(response.precio_compra_producto),
          };
          this.detalleProductos.push(item);
          this.productoSeleccionado = {};
          this.cantidad = 1;
          this.enviando = false;
          this.calcularTotal();
        });
    },
  },
  watch: {
    mostrar(newVal) {
      this.fecha_compra = new Date();
      this.activarModal = newVal;
      this.calcularTotal();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
