<template>
  <ConfirmDialog ></ConfirmDialog>
  <Toast />
  <ComprasCreate
    :mostrar="NuevaCompra"
    @closeModal="closeModal"
    @actualizarListadoOC="cargarOrdenesCompras"
  ></ComprasCreate>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Compras</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Listado de Compras</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar &Oacute;rdenes de Compras
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-tooltip.top="'Nueva Orden de Compra'"
          v-if="'Compra Crear' in auth.user.permissions"
          class="p-button-outlined mr-2"
          icon="pi pi-plus"
          @click="activarModal"
        ></Button>
      </div>
    </div>

    <div class="mt-4">
      <DataTable
        ref="dt_oc_list"
        :value="ordenes_compras"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Orden de Compras"
        responsiveLayout="scroll"
        :rowHover="true"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
        <template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty> <span class="flex align-items-center justify-content-center p-invalid">No existen Registros!</span></template>

				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
        <Column
          field="id"
          header="CÓDIGO"
          :sortable="true"
          exportHeader="Codigo CLiente"
        >
          {{ data.id }}
        </Column>
        <Column field="nombre_user" header="USUARIO SOLICITUD"></Column>
        <Column field="nombre_proveedor" header="PROVEEDOR">
          {{ data.nombre_proveedor }}
        </Column>
        <Column field="created_at" header="FECHA DE SOLICITUD" :sortable="true">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>

        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              v-tooltip.top="'Ver Acciones'"
              class="p-button-icon-only p-button-raised"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ComprasCreate from "@/module/compras/ComprasCreate.vue";
import OrdenCompraService from "@/service/OrdenCompraService";
import { watchEffect } from "vue";
import { useAuth } from "@/stores";

import { useMagicKeys } from "@vueuse/core";
const { shift, n /* keys you want to monitor */ } = useMagicKeys();

watchEffect(() => {
  if (shift.value && n.value) this.activarModal();
});

export default {
  name: "Compras",
  components: {
    ComprasCreate,
  },
  data() {
    return {
      NuevaCompra: false,
      ordenes_compras: [],
      cargando: true,
      buscar: {},
      claseEstado: ["status-outofstock","status-lowstock", "customer-badge status-new", "status-instock"],
    };
  },
  ordenesCompras: null,
  auth: useAuth(),
  created() {
    this.auth = useAuth();
    this.ordenesCompras = new OrdenCompraService();
    this.initBuscar();

    document.onkeydown = this.KeyPress;
  },
  mounted() {
    this.cargarOrdenesCompras();
  },
  methods: {
    KeyPress(e) {
      var evtobj = window.event ? event : e;
      if ((evtobj.keyCode == 73 || evtobj.keyCode == 115) && evtobj.ctrlKey)
        this.activarModal();
    },
    acciones(datos) {
      return [
        {
          label: "Ver OC",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Compra Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/orden_compra/${datos.id}/detalle`,
        },
        {
          label: "Recibir OC",
          disabled: datos.estado != 1 ? true : false,
          visible:
            "Compra Recibir" in this.auth.user.permissions ? true : false,
          icon: "pi pi-fw pi-save",
          to: `/oc/${datos.id}/recibir`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Compra DescargarPDF" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => {
            this.imprimirOC(datos.id);
          },
        },
        {
          label: "Recibo OC",
          disabled: datos.estado != 2 ? true : false ||
            "Compra Ver DetalleRecepcion" in this.auth.user.permissions
              ? false
              : true,
          icon: "pi pi-fw pi-list",
          to: `/oc/${datos.id}/detalle_recepcion`,
        },
        {
          label: "Anular OC",
          disabled: datos.estado != 1 ? true : false,
          visible:
            "Compra Anular" in this.auth.user.permissions ? true : false,
          icon: "pi pi-fw pi-times",
          command: () => {
            this.anularOC(datos.id);
          },
        },
        {
          label: "Pagar OC",
          disabled: datos.estado != 2 ? true : false,
          icon: "pi pi-fw pi-money-bill",
          to: '/proveedorpagos',
        }
      ];
    },
    anularOC(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la Orden de Compra?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo Anular",
        accept: () => {
          this.ordenesCompras.anular(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: response.mensaje,
              life: 3000,
            });
            this.cargando = true;
            this.cargarOrdenesCompras();
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
        onHide: () => {
          //Callback to execute when dialog is hidden
        },
      });
    },
    imprimirOC(id) {
      this.ordenesCompras
        .imprimirOC(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    recibirOC(data) {
      this.$router.push(`/oc/${data.id}/recibir`);
    },
    cargarOrdenesCompras() {
      this.ordenesCompras
        .getAllOrdenesCompra()
        .then((data) => {
          this.ordenes_compras = data.ordenes_compra;
          this.ordenes_compras.forEach((oc) => {
            oc.created_at = new Date(oc.created_at);
            oc.updated_at = new Date(oc.updated_at);
          });
          this.cargando = false;

        })
        .catch((error) => {
          console.log(error);
          this.cargando = false;
        });
    },
    activarModal() {
      this.NuevaCompra = true;
    },
    closeModal() {
      this.NuevaCompra = false;
      this.orden_compra = {};
    },
    verDetalle(orden_compra) {
      this.$router.push(`/orden_compra/${orden_compra.id}/detalle`);
    },
    /* textoEstado(estado) {
      return estado == 1 ? "Recibida" : "Pendiente Entrega";
    }, */
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
